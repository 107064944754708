$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.DailyGame {
  .Flex--gapButtons {
    @include customFlexGap ($spacing * 0.5);
  }

  .DailyGame__Description {
    font-size: 90%;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2;
  }
}

.DailyGame__Image {
  border-radius: 7px;
}

.DailyGameUpsellBox {
  border: solid 1px;
  padding: $boxPadding * 0.5;
  margin-top: $marginSm3;

  p {
    font-size: 80%;
    margin-left: $marginSm2;
    line-height: 1;
  }

  .Flex {
    position: relative;
  }
}

.DailyGame__Billboards {
  margin: $marginSm1 0 $marginSm0 0;

  > .Flex {
    margin-left: $marginSm3;
    margin-right: $marginSm3;
  }

  img {
    border-radius: 5px;
  }

  a:not(.Button) {
    word-spacing: 0;
    font-size: 0;
    
    .Button {
      font-size: 0.65rem;
      text-transform: uppercase;
    }

    + .Button {
      margin-top: $marginSm2;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }
  }
}

.DailyGame__BillboardLabel {
  height: auto !important;
  padding: 0 !important;
  display: block;
  margin-bottom: $marginSm1;
}

.DailyGameChallengeBox {
  border: solid 1px;
  font-size: 90%;
  line-height: 1.4;
  margin-top: $marginSm3;

  p:not(:last-child) {
    margin-bottom: $marginSm1;
  }

  span {
    font-size: 80%;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 3px;
  }
}