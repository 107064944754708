$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.GridUpsellPod {
  position: relative;
  overflow: hidden;
  border-radius: $radius;
  display: flex;
  flex-direction: row;
  user-select: none;
  min-height: 200px;
  height: 269px;

  &--title {
    margin-bottom: $spacing;

    figure {
      height: 48px;
      width: 48px;

      margin-right: $spacing;

      position: relative;
      
    }
  }

  > section {
    padding: $spacing * 1.5;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0;
    font-weight: 300;

    &:last-child .--hideOnDesktopDisplays {
      flex: 1
    }

    &:last-child .--hideOnMobileDisplays {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }

  h4,
  p {
    margin-bottom: $marginSm0;
  }

  h3 {
    font-weight: bold;
    font-size: 22px;
  }

  p {
    font-size: 12px;
    font-weight: 600;
  }

  &__RightText {
    text-align: center;

    @include isMobileDevice {
      font-size: 12px;
    }
  }

  @include isMobileDevice {
    .Button {
      width: 100%;
    }
  }

  @include isDesktopDevice {
    .Button {
      min-width: 210px;
    }
  }
  
  @media screen and (min-width: 768px){
    > section:last-child {
      flex: auto;
      min-width: $dailyTileMediaWidth;
      max-width: $dailyTileMediaWidth;
    }
  }

}
