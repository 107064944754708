$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.DailyGameTileContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: $spacing;
  grid-row-gap: $spacing;

  @media screen and (min-width: 350px) {
    grid-template-columns: 1fr 1fr;
  }

  &--mobileSingleColumn {
    @media screen and (min-width: 350px) {
      grid-template-columns: 1fr;

      > * {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &--featured {
    > *:nth-child(3) {
      display: none;
    }

    @media screen and (min-width: 768px) {
      grid-template-columns: 1fr 2fr 1fr;

      > *:nth-child(3) {
        display: block;
      }
    }
  }

  &--compact {
    @media screen and (min-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &--quad {
    @media screen and (min-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  &--flex {
    display: flex;
  }

  &--hero {
    display: flex;

    > *:first-child {
      flex: 1;
    }

    > *:not(:first-child) {
      min-width: 300px;
      max-width: 300px;
      min-height: 250px;
      max-height: 250px;
    }
  }
}
