$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.DailyGameTile {
  position: relative;
  text-align: center;
  overflow: hidden;
  border-radius: $radius;
  min-height: 192px;
  display: flex;
  flex-direction: column;
  user-select: none;

  > header,
  > section {
    padding: $spacing * 1.5;

    > * {
      &:not(:last-child) {
        margin-bottom: $spacing;
      }
    }
  }

  > section {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  button {
    margin: 0 auto;
    margin-top: auto;
    max-width: 165px;
    width: 100%;
  }

  h3 {
    font-weight: bold;
    min-height: 22px;
    font-size: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  p,
  .p {
    min-height: 12px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 300;
  }

  figure {
    width: 100%;
    min-width: 58px;
    height: 58px;
    display: block;
    position: relative;
    overflow: hidden;

    img {
      height: 58px;
    }
  }

  &__Buttons {
    margin-top: auto;

    .Button {

      +.Button {
        margin-top: $marginSm2;
      }
    }
  }

  &--featured,
  &--minimal,
  &--hero {
    min-height: 192px;
    display: flex;
  }

  &--featured {
    display: flex;
    align-items: center;
    justify-content: center;

    > section {
      flex: 0;
    }

    &::after {
      display: none;
    }
  }

  &--media,
  &--mediaDetails,
  &--grid {
    flex: 1;
    min-width: 150px;

    > header {
      height: 300px;
      padding: 0;
    }

    > figure {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &--media {
    .DailyGameTile__header {
      cursor: pointer;
    }
    
    figure {
      height: 100%;
      width: 100%;
    }
  }

  &--simple {
    min-height: 150px;
  }

  &--mediaDetails {
    width: 207px;
    min-height: 0;

    > header,
    > header figure,
    > header img {
      width: 207px;
      height: 113px;
    }

    > section {
      text-align: left;
      padding: $marginMd0;
    }

    > section > p:first-child {
      font-weight: bold;
      margin-bottom: $marginSm1;
    }

    p, 
    .p {
      font-size: 12px;
      overflow: visible;
      white-space: normal;
    }
  }

  &--media--featured {
    min-width: 599px;

    // @media screen and (min-width: #{$bodyMaxWidth}) {
    //   min-width: 600px;
    // }

    > header {
      aspect-ratio: 2 / 1;
    }
  }

  &--hero,
  &--mobilehero {
    flex-direction: row;
    align-items: strecth;

    >:first-child {
      padding-right: 0;
    }

    > header,
    > section {
      display: flex;
      flex: 1;
      flex-direction: column;
    }

    > section {
      justify-content: center;
    }

    > header {
      background-color: transparent !important;

      p,
      .p {
        overflow: visible;
        white-space: normal;
        height: auto;
      }
    }

    figure {
      font-size: 0;
      width: 100%;
      height: auto;
      border-radius: $radius;

      > img {
        border-radius: $radius;
      }
    }
  }

  &--mobilehero {
    figure {
      > img {
        width: auto !important;
        height: 105px !important;
      }
    }
  }

  &--hero {
    > header {
      justify-content: center;

      h3, 
      p,
      .p {
        text-align: left;
      }
    }

    p,
    .p {
      font-size: 12px;
    }

    figure {
      width: 249px !important;
      height: 137px !important;
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;
      position: relative;

      > img {
        width: auto !important;
        height: 137px !important;
        max-width: none;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .Button {
      width: 166px;
      margin: 0 auto;
    }

    &.HeroGameTile--fullWidth {
      section {
        width: 327px !important;
      }

      header {
        padding-left: 0;
      }

      figure {
        width: 327px !important;
        height: 180px !important;

        > img {
          height: 180px !important;
        }
      }
    }
  }

  &--wide {
    width: 100%;
    min-height: 0;
    height: 74px;
    flex-direction: row;

    figure { 
      font-size: 0;
      width: 134px !important;
      height: 74px !important;

      > img {
        width: 134px !important;
        height: 74px !important;
      }
    }

    > section {
      align-items: flex-start;
      font-size: 12px;
      min-width: 0;
      padding: $marginMd0;

      p,
      .p {
        text-align: left;
        width: 100%;
        overflow: visible;
        white-space: normal;
        height: auto;
        max-width: 486px;
      }

      .DailyGameTile__Title {
        font-weight: bold;
        margin-bottom: $marginSm1;
      }
    }

    &--hero {
      height: 195px !important;
      flex-direction: row;

      figure { 
        font-size: 0;
        width: 351px !important;
        height: auto !important;

        > img {
          width: auto !important;
          height: 195px !important;
        }
      }
    }
  }

  &--playhero {
    min-height: 250px;

    section {
      justify-content: center;
      padding-left: 0;
      min-width: 249px;
      box-sizing: content-box;
    }

    header {
      text-align: left;
      max-height: 329px;
      margin: auto;
      padding-right: $marginMd0 !important;

      .Button {
        margin-left: 0 !important;
      }
    }

    h3 {
      white-space: normal !important;
    }

    figure {
      margin: auto;
      width: 249px !important;
      height: 157px !important;
      border-radius: $radius;
      overflow: hidden; 

      img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 287px !important; // 234px
        height: 157px !important;
        max-width: none;
      }
    }

    &.DailyGameTile--playhero--isJigsaw {
      figure {
        img {
          width: 234px !important;
          height: 157px !important;
        }
      }
    }

    &.DailyGameTile--playhero--isFullWidth {
      header {
        margin-left: 80px !important;
        padding: 0;
      }

      figure {
        margin: auto;
        width: 311px !important;
        height: 195px !important;

        img {
          width: 356px !important;
          height: 195px !important;
        }
      }

      &.DailyGameTile--playhero--isJigsaw {
        figure {
          width: 284px !important;
          height: 195px !important;

          img {
            width: 284px !important;
            height: 195px !important;
          }
        }
      }
    }
  }

  &--mobilehero {
    min-height: 0;
    height: 145px;

    > header {
      justify-content: center;
      align-items: flex-start;

      .Button {
        margin: 0;
        min-width: 112px;
      }
    }

    > section {
      padding-top: 0;
      padding-bottom: 0;
      padding-right: $marginSm3;
      justify-content: center;
      align-items: center;
      display: flex;
    }

    figure {
      min-width: 156px;
      max-width: 156px;

      img {
        border-radius: $radius;
        width: auto !important;
        height: 105px !important;
      }
    }

    @media (max-width: 360px) {
      > header {
        padding: 0.75rem 0 0.75rem 0.75rem;
      }

      img {
        transform-origin: 25% 50%;
        transform: scale(0.8);
      }
    }
  }

  &--grid {
    text-align: left;
    font-size: 90%;
    width: 258px;

    h4 {
      margin-bottom: $marginSm2 !important;
      font-weight: bold;
    }

    p,
    .p {
      overflow: visible;
      white-space: normal;
      height: auto;
    }

    > header,
    > header figure,
    > header img {
      width: 258px;
      height: 141px;
    }

    > section {
      padding: $spacing;
      align-items: start;
    }
  }

  &--bonus {

    > header {
      padding-bottom: 0;
    }

    > section {
      padding-top: 0;

    }

    .DailyGameTile__ArchiveDate {
      font-size: 18px;
    }
  }
}
