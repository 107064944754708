$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.ImageUpsellPod {
  position: relative;
  overflow: hidden;
  border-radius: $radius;
  display: flex;
  flex-direction: column;
  user-select: none;
  height: 200px;

  > figure {
    height: 200px;
    width: 331px;
    position: relative;
    display: none;
    flex: 1;
  }

  > section {
    padding: $spacing;
    display: flex;
    flex-direction: column;
  }

  h3,
  p {
    margin-bottom: $spacing;
  }

  h3 {
    font-weight: bold;
    font-size: 22px;
  }

  p {
    font-size: 12px;
    font-weight: 600;
  }

  img {
    object-fit: cover;
  }
  
  .Button {
    margin-top: auto;
    margin-right: auto;
    min-width: 200px;
  }

  @media screen and (min-width: 500px) {
    > figure {
      display: block;
    }
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;

    > figure {
      order: 2;
    }

    > section {
      min-width: $dailyTileMediaWidth;
      max-width: $dailyTileMediaWidth;
    }
  }
}
